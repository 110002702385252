/* General Styles */
* {
    box-sizing: border-box;
}

a,
header a,
div.post div.texts a,
div.post-page a { 
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

body {
    font-family: sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

/* Error Message */
.error-message {
    color: #dc3545;
    font-size: 0.9rem;
    margin: 10px 0;
    display: block;
}

/* Layout */
main {
    padding: 20px;
    max-width: 960px;
    margin: 20px auto;
}

/* Header */
header {
    background-color: #333;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 50px;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

header a.logo {
    font-weight: bold;
    font-size: 1.8rem;
    text-align: left;
    color: #fff;
    text-decoration: none;
}

header nav {
    display: flex;
    gap: 1.5rem;
}

header nav a {
    text-decoration: none;
    color: #fff;
    font-size: 1.1rem;
    font-family: inherit;
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    white-space: nowrap;
    transition: color 0.3s, background-color 0.3s;
}

header nav a:hover {
    color: #ccc;
    background-color: rgba(255, 255, 255, 0.1);
}

.hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.hamburger .line {
    width: 100%;
    height: 3px;
    background-color: #fff;
    transition: all 0.3s;
}

/* Responsive adjustments */
@media (max-width: 783px) {
    header {
        flex-wrap: wrap;
    }

    header nav {
        display: none;
        width: 100%;
        background-color: #333;
        padding: 1rem;
        box-sizing: border-box;
        flex-direction: column;
        gap: 1rem;
    }

    header nav.open {
        display: flex;
    }

    .hamburger {
        display: flex;
    }

    .hamburger.open .line:nth-child(1) {
        transform: translateY(10px) rotate(45deg);
    }

    .hamburger.open .line:nth-child(2) {
        opacity: 0;
    }

    .hamburger.open .line:nth-child(3) {
        transform: translateY(-10px) rotate(-45deg);
    }
}

/* Resume Styles */
.resume {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    line-height: 1.6;
  }
  
  .resume h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .resume h2 {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #333;
    padding-bottom: 0.5rem;
  }
  
  .resume h3 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .resume h4 {
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .resume p {
    margin-bottom: 1rem;
  }
  
  .resume ul {
    margin-bottom: 1rem;
    padding-left: 20px;
  }
  
  .resume li {
    margin-bottom: 0.5rem;
  }
  
  .resume .contact {
    margin-bottom: 2rem;
  }
  
  .resume .contact p {
    margin-bottom: 0.5rem;
  }
  
  .resume .contact a {
    text-decoration: none;
    border-bottom: none;
    transition: border-bottom 0.3s;
  }
  
  .resume .contact a:hover {
    border-bottom: 1px solid #004499;
  }
  
  .resume section > p:first-of-type {
    margin-bottom: 0;
  }
  
  .resume section > p:last-of-type {
    margin-top: 0;
  }
  
  .resume .dates {
    font-style: italic;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  .resume .tasks {
    margin-left: 0;
    padding-left: 20px;
  }
  
  .resume .tasks li {
    margin-bottom: 1rem;
  }
  
  .resume .tasks .details {
    margin-left: 0;
    padding-left: 20px;
  }
  
  .resume a {
    color: #0066cc;
    text-decoration: none;
    border-bottom: 1px solid #0066cc;
    transition: color 0.3s, border-color 0.3s;
  }
  
  .resume a:hover {
    color: #004499;
    border-bottom-color: #004499;
  }
  
  .resume ul li a {
    display: inline-block;
    padding: 2px 0;
    text-decoration: none;
    border-bottom: none;
  }
  
  .resume ul li a:hover {
    text-decoration: underline;
  }
  
  .resume .projects a {
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .resume {
      padding: 1rem;
    }
  
    .resume h1 {
      font-size: 2rem;
    }
  
    .resume h2 {
      font-size: 1.75rem;
    }
  
    .resume h3 {
      font-size: 1.3rem;
    }
  
    .resume h4 {
      font-size: 1.1rem;
    }
  
    .resume ul,
    .resume .tasks,
    .resume .tasks .details {
      padding-left: 15px;
    }
  }

/* Posts */
div.post {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 30px;
    border: 1px solid #dee2e6;
    padding: 20px;
    border-radius: 5px;
}

@media screen and (min-width: 700px) {
    div.post {
        grid-template-columns: 300px 1fr;
    }
}

.post .image,
div.post-page div.image {
    aspect-ratio: 16 / 9;
    overflow: hidden;

}


div.post div.texts h2 {
    margin: 0;
    font-size: 2rem;
}

div.post p.info {
    margin: 24px 0;
    color: #888;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    gap: 10px;
}

div.post p.info span.author {
    color: #333;
}

div.post p.summary {
    margin: 10px 0;
    line-height: 1.8rem;
}

.create-post-link {
    text-align: center;
    margin-bottom: 30px;
}

.create-post-link a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.create-post-link a:hover {
    background-color: #555;
}

@media screen and (max-width: 700px) {
    .create-post-link {
        margin-bottom: 20px;
    }
    
    .create-post-link a {
        display: block;
        width: 100%;
    }
}

.post-page .project-links {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.1rem;
  }
  
  .post-page .project-links a {
    color: #0066cc;
    text-decoration: none;
    border-bottom: 1px solid #0066cc;
    transition: color 0.3s, border-color 0.3s;
  }
  
  .post-page .project-links a:hover {
    color: #004499;
    border-bottom-color: #004499;
  }

  @media screen and (max-width: 483px) {
    div.edit-row {
        flex-direction: column;
        align-items: stretch;
    }

    div.post-page a.edit-btn {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
    }

    div.post-page a.edit-btn:first-child {
        margin-right: 0;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #f0f0f0;
    border: none;
    color: #333;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .pagination button:hover {
    background-color: #ddd;
  }
  
  .pagination button:disabled {
    background-color: #f0f0f0;
    color: #aaa;
    cursor: not-allowed;
  }
  
  .pagination span {
    margin: 0 10px;
    font-size: 16px;
  }

/* Forms */
form.login, form.register, form.contact {
    max-width: 400px;
    margin: 0 auto;
}

input {
    display: block;
    margin-bottom: 5px;
    width: 100%;
    padding: 5px 7px;
    border: 2px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}

button {
    cursor: pointer;
    width: 100%;
    display: block;
    background-color: #555;
    border: 0;
    color: #fff;
    border-radius: 5px;
    padding: 7px 0;
}

form.login h1, 
form.register h1, 
form.contact h1 {
    text-align: center;
}

textarea.message-input {
    width: 100%;
    padding: 5px 7px;
    border: 2px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    min-height: 200px;
    max-height: 400px;
}

/* Post Page */
div.post-page div.image {
    max-height: 300px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

div.post-page div.image img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
}

div.post-page h1 {
    text-align: center;
    margin: 10px 0 5px;
}

div.post-page time {
    text-align: center;
    display: block;
    font-size: 1rem;
    color: #aaa;
    margin: 10px 0;
}

div.post-page div.author {
    text-align: center;
    margin-bottom: 20px;
    font-size: 0.7rem;
    font-weight: bold;
}

div.content p {
    line-height: 1.7rem;
    margin: 30px 0;
}

div.content li {
    margin-bottom: 10px;
}

div.content a {
    color: #0066cc;
    text-decoration: none;
    border-bottom: 1px solid #0066cc;
    transition: color 0.3s, border-color 0.3s;
  }
  
  div.content a:hover {
    color: #004499;
    border-bottom-color: #004499;
  }

/* Edit buttons */
  div.edit-row {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }

div.post-page a.edit-btn {
    background-color: #333;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    color: #fff;
    padding: 15px 30px;
    border-radius: 5px;
    text-decoration: none;
}

div.post-page a.edit-btn:first-child {
    margin-right: 20px;
}

a svg {
    height: 20px;
}
/* Projects */
div.post {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 30px;
    border: 1px solid #dee2e6;
    padding: 20px;
    border-radius: 5px;
}

@media screen and (min-width: 700px) {
    div.post {
        grid-template-columns: 300px 1fr;
    }
}

.post .image,
div.post-page div.image {
    aspect-ratio: 16 / 9;
    overflow: hidden;
}

div.post div.texts h2 {
    margin: 0;
    font-size: 2rem;
}

div.post p.info {
    margin: 24px 0;
    color: #888;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    gap: 10px;
}

div.post p.info span.author {
    color: #333;
}

div.post p.summary {
    margin: 10px 0;
    line-height: 1.8rem;
}

.create-post-link {
    text-align: center;
    margin-bottom: 30px;
}

.create-post-link a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.create-post-link a:hover {
    background-color: #555;
}

@media screen and (max-width: 700px) {
    .create-post-link {
        margin-bottom: 20px;
    }
    
    .create-post-link a {
        display: block;
        width: 100%;
    }
}

.post-page .project-links {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.1rem;
}
  
.post-page .project-links a {
    color: #0066cc;
    text-decoration: none;
    border-bottom: 1px solid #0066cc;
    transition: color 0.3s, border-color 0.3s;
}
  
.post-page .project-links a:hover {
    color: #004499;
    border-bottom-color: #004499;
}

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #f0f0f0;
    border: none;
    color: #333;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .pagination button:hover {
    background-color: #ddd;
  }
  
  .pagination button:disabled {
    background-color: #f0f0f0;
    color: #aaa;
    cursor: not-allowed;
  }
  
  .pagination span {
    margin: 0 10px;
    font-size: 16px;
  }
  
 /* Contact Page */
.contact-info {
    margin-top: 20px;
  }
  
  .contact-info p {
    margin-bottom: 10px;
  }
  
  .contact-link {
    color: #0066cc;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: color 0.3s, border-color 0.3s;
  }
  
  .contact-link:hover {
    color: #004499;
    border-bottom-color: #004499;
  }
  
  .contact-info .contact-link {
    display: inline-block;
    padding: 2px 0;
  }

  .contact-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .contact-page form.contact {
    max-width: 100%;
    width: 100%;
  }
  
  .contact-page .contact-info {
    margin-top: 40px;
    border-top: 1px solid #ddd;
    padding-top: 20px;
  }
  
  .contact-page .contact-info p {
    margin-bottom: 10px;
  }
  
/* Comments */
.comment-section {
  margin-top: 2rem;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
}

.comment {
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.comment-section form {
  margin-top: 1rem;
}

.comment-section textarea {
  width: 100%;
  min-height: 100px;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.comment-section button {
  background-color: #333;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
}

.comment-section button:hover {
  background-color: #555;
}

/* Comment Section Styles */
.comment-section {
  margin-top: 2rem;
  border-top: 1px solid #e0e0e0;
  padding-top: 1rem;
}

.comment {
  border-bottom: 1px solid #e0e0e0;
  padding: 1rem 0;
  margin-bottom: 1rem;
}

.comment:last-child {
  border-bottom: none;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.5rem;
}

.comment-author {
  font-weight: bold;
  color: #333;
}

.comment-date {
  font-size: 0.8rem;
  color: #777;
}

.comment-content {
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.comment-actions {
  font-size: 0.8rem;
  color: #777;
}

.comment-action {
  color: #777;
  text-decoration: none;
  margin-left: 0.5rem;
  cursor: pointer;
}

.comment-action:hover {
  text-decoration: underline;
}

.comment-form textarea {
  width: 100%;
  min-height: 100px;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.comment-form button {
  background-color: #0066cc;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.comment-form button:hover {
  background-color: #0052a3;
}

/* Search results page */
.search-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.search-page h1 {
  margin-bottom: 20px;
}

.search-form {
  margin-bottom: 30px;
  position: relative;
}

.search-input-wrapper {
  display: flex;
  margin-bottom: 10px;
}

.search-input-wrapper input[type="text"] {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
  line-height: 28px;    /* 28px is the total height of the button's padding and font size */
  vertical-align: middle;
}

.search-input-wrapper button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  vertical-align: middle;
}

.search-content-option {
  position: absolute;
  left: -20px; /* Moves the container to the left edge of the screen */
  display: flex;
  align-items: flex-start;
  padding-left: 18px; /* Small padding between checkbox and left screen edge */
}


.search-content-option input[type="checkbox"] {
  margin-top: 2px
}

.search-content-option label {
  margin-left: 8px; /* Small padding between checkbox and label */
  white-space: nowrap;
}

.search-result {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.search-result h2 {
  margin-bottom: 10px;
}

.search-result p {
  margin-bottom: 5px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination a {
  padding: 5px 10px;
  background-color: #333;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.error-message {
  color: red;
  margin-bottom: 20px;
}

/* ReactQuill editor styles */
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
  width: 100%;
}

.ql-container.ql-snow {
  border: 1px solid #ccc;
  width: 100%;
}

.ql-editor {
  min-height: 200px;
  font-size: 16px;
}

/* Remove extra spacing */
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
}

/* Style the toolbar buttons */
.ql-snow .ql-toolbar button,
.ql-snow.ql-toolbar button {
  width: auto;
  height: auto;
  padding: 5px;
}

/* Adjust icon sizes */
.ql-snow .ql-stroke {
  stroke-width: 1.5px;
}

/* Remove the extra input field */
.ql-container > input[type="text"] {
  display: none;
}